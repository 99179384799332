import { Button, useTheme } from "@mui/material";
import { useMemo } from "react";

interface AppButtonProps {
  gradient: string;
  gradientDisabled: string;
  disabled?: boolean;
  children?: React.ReactNode;
  sx?: any;
}

export function AppButton({
  gradient,
  disabled,
  children,
  gradientDisabled,
  sx = {}
}: AppButtonProps) {
  const theme = useTheme();
  
  return (
    <Button
      disabled={disabled}
      sx={{
        background: disabled ? gradientDisabled : gradient,
        ...sx
      }}
    >
      {children}
    </Button>
  );
} 