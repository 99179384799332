import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const DefaultGemIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4108_7379)">
      <path
        d="M20.5 11.9945L12.25 23.989L4 11.9945L12.25 0L20.5 11.9945Z"
        fill="#5AFFAC"
      />
      <path d="M20.5 11.9941H4L12.25 23.9886L20.5 11.9941Z" fill="#40B777" />
      <path d="M12.25 0L8.17478 11.9901L4 11.9945L12.25 0Z" fill="#40B777" />
      <path
        d="M12.25 24.0002L8.17478 11.9902L4 12.0057L12.25 24.0002Z"
        fill="#409B77"
      />
      <path
        d="M12.248 0L16.3233 11.9901L20.498 11.9945L12.248 0Z"
        fill="#40B777"
      />
      <path
        d="M12.248 24.0002L16.3233 11.9902L20.498 12.0057L12.248 24.0002Z"
        fill="#409B77"
      />
      <path
        d="M12.2479 0L9.23094 11.2263L12.2479 24L8.1748 11.9901L12.2479 0Z"
        fill="white"
      />
      <path
        d="M16.3231 11.9899H8.1748L8.93444 10.9414L16.3231 11.9899Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4108_7379">
        <rect width="16.5" height="24" fill="white" transform="translate(4)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const DefaultDiamondIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="35"
    height="27"
    viewBox="0 0 35 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5282_5410)">
      <path
        d="M25.0053 0.00341797H9.99472L0 9.95629L17.5 26.9764L35 9.95629L25.0053 0.00341797Z"
        fill="#4970C9"
      />
      <path
        d="M24.999 0.00341797H21.2061L26.1413 9.94617L34.9937 9.95629L24.999 0.00341797Z"
        fill="#4970C9"
      />
      <path d="M35 9.95605H0L17.5 26.9762L35 9.95605Z" fill="#40ADE2" />
      <path
        d="M9.99472 0.00341797L0 9.95629L8.85247 9.94617L13.791 0.00341797H9.99472Z"
        fill="#4970C9"
      />
      <path
        d="M17.5 27.0002L8.85247 9.94629L0 9.98004L17.5 27.0002Z"
        fill="#4970C9"
      />
      <path
        d="M17.4932 27.0002L26.1407 9.94629L34.9932 9.98004L17.4932 27.0002Z"
        fill="#4970C9"
      />
      <path
        d="M26.1409 9.94612L17.4967 0L8.85254 9.94612H26.1409Z"
        fill="#4CDAFC"
      />
      <path
        d="M26.1409 9.94593H8.85254L10.7843 7.84668L26.1409 9.94593Z"
        fill="white"
      />
      <path
        d="M24.999 0.00341797L26.1413 9.94617L34.9937 9.97992L24.999 0.00341797Z"
        fill="#40ADE2"
      />
      <path
        d="M9.99472 0.00341797L8.85247 9.94617L0 9.97992L9.99472 0.00341797Z"
        fill="#40ADE2"
      />
      <path
        d="M17.4967 0L8.85254 9.94613L17.4934 27L11.0967 8.34975L17.4967 0Z"
        fill="white"
      />
      <path
        d="M0 9.98008L11.1135 7.95508L8.85247 9.94633L0 9.98008Z"
        fill="white"
      />
      <path
        d="M33.7568 8.82227L34.9931 9.97989L26.1406 9.94614L33.7568 8.82227Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5282_5410">
        <rect width="35" height="27" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export function FlagIcon(props: SvgIconProps) {
  const theme = useTheme();
  // @ts-ignore
  const contrast = theme.palette.contrast.main;

  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4107_20101)">
        <path
          opacity="0.3"
          d="M12.36 6H7V12H14.24L14.64 14H18V8H12.76L12.36 6Z"
          fill={contrast}
          fillOpacity="0.6"
        />
        <g filter="url(#filter0_d_4107_20101)">
          <path d="M14.4 6L14 4H5V21H7V14H12.6L13 16H20V6H14.4ZM18 14H14.64L14.24 12H7V6H12.36L12.76 8H18V14Z" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4107_20101"
          x="3"
          y="2"
          width="19"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4107_20101"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4107_20101"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4107_20101">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function DownloadIcon(props: SvgIconProps) {
  const theme = useTheme();
  // @ts-ignore
  const contrast = theme.palette.contrast.main;

  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4107_19940)">
        <path
          opacity="0.3"
          d="M19.21 12.04L17.68 11.93L17.38 10.43C16.88 7.86 14.62 6 12 6C9.94 6 8.08 7.14 7.12 8.96L6.62 9.91L5.55 10.02C3.53 10.24 2 11.95 2 14C2 16.21 3.79 18 6 18H19C20.65 18 22 16.65 22 15C22 13.45 20.78 12.14 19.21 12.04ZM12 17L8 13H10.55V10H13.46V13H16L12 17Z"
          fill={contrast}
        />
        <g filter="url(#filter0_d_4107_19940)">
          <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM19 18H6C3.79 18 2 16.21 2 14C2 11.95 3.53 10.24 5.56 10.03L6.63 9.92L7.13 8.97C8.08 7.14 9.94 6 12 6C14.62 6 16.88 7.86 17.39 10.43L17.69 11.93L19.22 12.04C20.78 12.14 22 13.45 22 15C22 16.65 20.65 18 19 18ZM13.45 10H10.55V13H8L12 17L16 13H13.45V10Z" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4107_19940"
          x="-2"
          y="2"
          width="28"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4107_19940"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4107_19940"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4107_19940">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
export function ActiveIcon(props: SvgIconProps) {
  const theme = useTheme();
  // @ts-ignore
  const primary = theme.palette.primary.main;
  // @ts-ignore
  const secondary = theme.palette.secondary.main;
  // @ts-ignore
  const contrast = theme.palette.contrast.main;
  return (
    <SvgIcon
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M13.9999 4.40002C8.7079 4.40002 4.3999 8.70802 4.3999 14C4.3999 19.292 8.7079 23.6 13.9999 23.6C19.2919 23.6 23.5999 19.292 23.5999 14C23.5999 8.70802 19.2919 4.40002 13.9999 4.40002ZM10.9999 19.4V8.60002L19.3999 14L10.9999 19.4Z"
        fill={contrast}
        fillOpacity="0.6"
      />
      <g filter="url(#filter0_d_4107_19946)">
        <path
          d="M14 2C7.376 2 2 7.376 2 14C2 20.624 7.376 26 14 26C20.624 26 26 20.624 26 14C26 7.376 20.624 2 14 2ZM14 23.6C8.708 23.6 4.4 19.292 4.4 14C4.4 8.708 8.708 4.4 14 4.4C19.292 4.4 23.6 8.708 23.6 14C23.6 19.292 19.292 23.6 14 23.6Z"
          fill={secondary}
        />
      </g>
      <path d="M11 19.4L19.4 14L11 8.59998V19.4Z" fill={primary} />
      <defs>
        <filter
          id="filter0_d_4107_19946"
          x="0"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4107_19946"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4107_19946"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
}

export function InactiveIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="10"
        fill="none"
        stroke="#B3B3B3"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}

export const CompletedIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_5240_6995)">
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
    </g>
    <defs>
      <filter
        id="filter0_d_5240_6995"
        x="0"
        y="0"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5240_6995"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5240_6995"
          result="shape"
        />
      </filter>
    </defs>
  </SvgIcon>
);

export const ClockIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  // @ts-ignore
  const contrast = theme.palette.contrast.main;
  // @ts-ignore
  const primary = theme.palette.primary.main;

  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5257_8522)">
        <path
          opacity="0.3"
          d="M12.0698 6.00977C8.19982 6.00977 5.06982 9.13977 5.06982 13.0098C5.06982 16.8798 8.19982 20.0098 12.0698 20.0098C15.9398 20.0098 19.0698 16.8798 19.0698 13.0098C19.0698 9.13977 15.9398 6.00977 12.0698 6.00977V6.00977ZM13.0698 14.0098H11.0698V8.00977H13.0698V14.0098Z"
          fill={contrast}
        />
        <g filter="url(#filter0_d_5257_8522)">
          <path
            d="M9.06982 1.00977H15.0698V3.00977H9.06982V1.00977ZM11.0698 8.00977H13.0698V14.0098H11.0698V8.00977ZM19.0998 7.38977L20.5198 5.96977C20.0898 5.45977 19.6198 4.97977 19.1098 4.55977L17.6898 5.97977C16.1398 4.73977 14.1898 3.99977 12.0698 3.99977C7.09982 3.99977 3.06982 8.02977 3.06982 12.9998C3.06982 17.9698 7.08982 21.9998 12.0698 21.9998C17.0498 21.9998 21.0698 17.9698 21.0698 12.9998C21.0698 10.8898 20.3298 8.92977 19.0998 7.38977ZM12.0698 20.0098C8.19982 20.0098 5.06982 16.8798 5.06982 13.0098C5.06982 9.13977 8.19982 6.00977 12.0698 6.00977C15.9398 6.00977 19.0698 9.13977 19.0698 13.0098C19.0698 16.8798 15.9398 20.0098 12.0698 20.0098Z"
            fill={primary}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_5257_8522"
          x="1.06982"
          y="-0.990234"
          width="22"
          height="24.9902"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5257_8522"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5257_8522"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_5257_8522">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
