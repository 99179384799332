import { useAssets } from '@/context/AssetsProvider';
import { IGQLJrnyUpdateCheckpointInput, IGQLJrnyUpdateCourseInput } from '@/graphql-types';
import { useLocalChange } from '@/hooks/use-local-change';
import MapEditor from '@/pages/course/components/MapEditor';
import { generateDebug } from '@/utils';
import DraggableList from '@components/draggable/DraggableList';
import { MinimalFileObject } from '@components/file-object/types';
import { NetworkContext } from '@/context/NetworkProvider';

import { IGQLCourseQuery, useCourseQuery, useUpdateCourseMutation } from '@graphql/courses-hook';
import {
  Avatar,
  Button, Chip,
  Fab,
  FormControl,
  Grid,
  IconButton, InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText, MenuItem,
  Paper, Select,
  TextField
} from '@mui/material';
import { uuidv4 } from 'lib0/random';
import { isEqual } from 'lodash'
import * as React from 'react'
import { DraggableProvided, DraggableStateSnapshot, DropResult } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import EditCheckpointDialog from './components/EditCheckpointDialog';
import { SimpleCheckpoint } from './types';
import { IconAdd, IconCheckpoint, IconDelete, IconDownloadCSV, IconEdit, IconGoTo } from "@/icons";
import { BackButton } from "@components/BackButton";
import { useCallback, useContext } from "react";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const debug = generateDebug('CourseDetailsView');

type Course = IGQLCourseQuery['jrnyCourse']

interface CourseDetailsViewProps {

}

const getModifications = (orgCourse: Course, localCourse: Course): IGQLJrnyUpdateCourseInput => {
  const checkpointsAdded = localCourse.checkpoints
    .filter(lcp => !orgCourse.checkpoints.find(cp => cp.id === lcp.id))
    .map(lcp => ({ id: lcp.id, order: lcp.order, name: lcp.name, courseId: orgCourse.id, coordinates: { ...lcp.coordinates, __typename: undefined } }))
  const checkpointsDeleted = orgCourse.checkpoints
    .filter(cp => !localCourse.checkpoints.find(lcp => cp.id === lcp.id))
    .map(cp => ({ id: cp.id }))
  const checkpointsModified = localCourse.checkpoints
    .map(lcp => {
      const org = orgCourse.checkpoints.find(cp => cp.id === lcp.id)
      if (!org || isEqual(lcp, org)) {
        return null
      }
      const update: IGQLJrnyUpdateCheckpointInput = { id: lcp.id }
      if (org.name !== lcp.name) {
        update.name = lcp.name
      }
      if (org.order !== lcp.order) {
        update.order = lcp.order
      }
      if (!isEqual(org.coordinates, lcp.coordinates)) {
        update.coordinates = { x: lcp.coordinates.x, y: lcp.coordinates.y }
      }
      return update
    })
    .filter(lcp => lcp !== null) as IGQLJrnyUpdateCheckpointInput[]
  return {
    id: orgCourse.id,
    mapImageId: localCourse.mapImageId,
    name: localCourse.name,
    available: localCourse.available,
    leaderBoardRows: localCourse.leaderBoardRows,
    checkpointsAdded,
    checkpointsDeleted,
    checkpointsModified
  }
}
export default function CourseDetailsView(props: CourseDetailsViewProps) {
  const [currentCP, setCurrentCheckPoint] = React.useState<SimpleCheckpoint>()
  const { showFileChooser } = useAssets()

  const navigate = useNavigate()
  const { courseId } = useParams()


  const [course] = useCourseQuery({ variables: { id: courseId || '' }, pause: !courseId })
  const { get } = useContext(NetworkContext)
  const downloadFile = useCallback(async () => {
    // Fetch the file data from the server
    const response = await get(`/api/course/${courseId}/submissions`, { responseType: 'blob' });

    // Check if the request was successful
    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file data from the response
    const fileBlob = response.data;

    const contentDisposition = response.headers.get('Content-Disposition') || '';
    const match = contentDisposition.match(/filename="(.+)"/i);
    const filename = match ? match[1] : `${course.data?.jrnyCourse.name}-submissions (downloaded ${dayjs().tz('utc').format('YYYY-MM-DD HHmmss UTC')}).csv`;
    // Create a URL representing the file
    const fileURL = URL.createObjectURL(fileBlob);

    // Create a link and click it to start the download
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename;
    link.click();

    // Release the reference to the file by revoking the URL
    URL.revokeObjectURL(fileURL);
  }, [course])
  const [_, updateCourse] = useUpdateCourseMutation()
  const [localCourse, setLocalCourse] = useLocalChange(course.data?.jrnyCourse,
    (original, local) => {
      const input = getModifications(original, local)
      return updateCourse({ input })
    })

  const addCheckpoint = React.useCallback(() => {
    setLocalCourse(draft => {
      const newCP = {
        __typename: 'JrnyCheckpoint',
        courseId: courseId!,
        name: 'New Checkpoint ' + (draft!.checkpoints.length + 1),
        id: uuidv4(),
        order: draft!.checkpoints.length,
        coordinates: { x: 0.5, y: 0.5, __typename: 'JrnyCoordinates' }
      } as const
      draft!.checkpoints.push(newCP)
    })
  }, [])


  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    setLocalCourse(draft => {
      const moved = draft!.checkpoints.splice(source.index, 1)
      draft!.checkpoints.splice(destination.index, 0, ...moved)
      draft!.checkpoints.forEach((p, i) => p.order = i)
    })
  };

  const onSetName = (item: SimpleCheckpoint, newName: string) => {
    setLocalCourse(draft => {
      const found = draft!.checkpoints.find(p => p.id === item.id)
      if (found) {
        found.name = newName
      }
    })
    setCurrentCheckPoint(undefined)
  }
  const onSetCoordinates = (item: SimpleCheckpoint, coordinates: { x: number, y: number }) => {
    setLocalCourse(draft => {
      const found = draft!.checkpoints.find(p => p.id === item.id)
      if (found) {
        found.coordinates = { ...coordinates, __typename: 'JrnyCoordinates' }
      }
    })
    setCurrentCheckPoint(undefined)
  }
  const onSetImage = (fileObject: MinimalFileObject | null) => {
    setLocalCourse(draft => {
      if (draft) {
        if (fileObject) {
          // @ts-ignore
          draft.mapImage = fileObject
          draft.mapImageId = fileObject.id

        } else {
          draft.mapImage = null
          draft.mapImageId = null
        }
      }
    })

  }
  const onDelete = (index: number) => {
    setLocalCourse(draft => {
      draft!.checkpoints.splice(index, 1)
      draft!.checkpoints.forEach((p, i) => p.order = i)
    })
  }
  const onClose = () => setCurrentCheckPoint(undefined)
  const onEdit = (item: SimpleCheckpoint) => setCurrentCheckPoint(item)
  const onNavigate = (item: SimpleCheckpoint) => navigate('../checkpoint/' + item.id)

  const listItemFactory = React.useCallback(
    (item: SimpleCheckpoint,
      index: number,
      provided: DraggableProvided,
      snapshot: DraggableStateSnapshot) => (<ListItem
        secondaryAction={(<>
          <IconButton onClick={() => onDelete(index)} edge='end'><IconDelete /></IconButton>
          <IconButton onClick={() => onEdit(item)} edge='end'><IconEdit /></IconButton>
          <IconButton onClick={() => onNavigate(item)} edge='end'><IconGoTo /></IconButton>
        </>)
        }
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : {}}
      >
        <ListItemAvatar>
          <Avatar>
            <IconCheckpoint />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={(item.order + 1) + ' ' + item.name} secondary={item.id} />
      </ListItem>
    ), [])

  return localCourse
    ? (<>
      <BackButton sx={{ mb: 1 }} />
      <Paper sx={{ mb: 2 }}>
      <ListItem onClick={() => navigate('../course-settings/' + courseId)} secondaryAction={(<>
          <IconButton edge='end'><IconGoTo /></IconButton>
        </>)
        }
        >
          <ListItemText primary={'Settings'} />
        </ListItem>
        <ListItem onClick={() => navigate('../course-users/' + courseId)} secondaryAction={(<>
          <IconButton edge='end'><IconGoTo /></IconButton>
        </>)
        }
        >
          <ListItemText primary={'Users'} />
        </ListItem>
        <ListItem onClick={() => navigate('../course-occasions/' + courseId)} secondaryAction={(<>
          <IconButton edge='end'><IconGoTo /></IconButton>
        </>)
        }
        >
          <ListItemText primary={'Occasions'} />
        </ListItem>
        <ListItem onClick={downloadFile} secondaryAction={(<>
          <IconButton edge='end'><IconDownloadCSV /></IconButton>
        </>)
        }
        >
          <ListItemText primary={'User submissions'} />
        </ListItem>
      </Paper>
      {localCourse.mapImage && <MapEditor
        onUpdateCoordinate={onSetCoordinates}
        checkpoints={localCourse.checkpoints} map={localCourse.mapImage} />}
      <Button onClick={() => showFileChooser({ type: 'image', onSelect: onSetImage })}>Choose image</Button>

      <Paper sx={{ p: 2 }}>
        <Grid container spacing={1}>
          <Grid item sm={7}><TextField
            label='Name/Title'
            fullWidth
            size='small'
            value={localCourse.name}
            onChange={(evt) => setLocalCourse(draft => {
              draft!.name = evt.target.value
            })}
          /></Grid>          
          <Grid item xs={3}>
            <TextField
              size='small'
              type={'number'}
              label='Leader board rows'
              value={localCourse.leaderBoardRows}
              onChange={(evt) => setLocalCourse(draft => {
                draft!.leaderBoardRows = Math.max(0, Number.parseFloat(evt.target.value))
              })} />
          </Grid>

          <Grid item sm={2}>
            <FormControl fullWidth>
              <InputLabel id="available-label">Available</InputLabel>
              <Select
                labelId="available-label"
                label='Available'
                size='small'
                displayEmpty
                value={localCourse.available ? '1' : '_'}
                onChange={(evt) => setLocalCourse(draft => {
                  draft!.available = evt.target.value == '1'
                })}>
                <MenuItem value='_'>No</MenuItem>
                <MenuItem value='1'>Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>


        </Grid>
        <DraggableList
          items={localCourse.checkpoints}
          onDragEnd={onDragEnd}
        >{listItemFactory}</DraggableList>
      </Paper>
      <EditCheckpointDialog
        checkpoint={currentCP}
        onCancel={onClose}
        onSave={onSetName}
      />
      <Fab color='primary' onClick={addCheckpoint} sx={{ position: 'fixed', bottom: 24, right: 24 }}><IconAdd /></Fab>
    </>)
    : (<Paper>Nothing</Paper>)
}

