import {ThemeOptions} from '@mui/material';
import {createTheme} from '@mui/material';
import BkgImage from '@/assets/journey-bg-bw.jpg';
import FuturaPT from '@/assets/fonts/FuturaPTDemi.woff2'
import FuturaPTBold from '@/assets/fonts/FuturaPTBold.woff2'
import FuturaPTOblique from '@/assets/fonts/FuturaPTDemiOblique.woff2'
import FuturaPTBoldOblique from '@/assets/fonts/FuturaPTBoldOblique.woff2'
import {Theme} from "@mui/material/styles";
import {deepmerge} from "@mui/utils";

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'FuturaPT',
    allVariants: {
      letterSpacing: 0.03
    },
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 18,
    },
    h3: {
      fontSize: 16,
    },
    body1: {
      fontSize: 14,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#40B777',
    },
    secondary: {
      main: '#F8ACFF',
    },
    contrast: {
      main: '#42368E',
    },
    info: {
      main: '#40ADE2',
    },
    background: {
      paper: 'rgba(0,0,0,0.5)',
      menu: 'rgba(51,51,51,.9)', // Background used in menus
      menuGradient: 'linear-gradient(180deg, rgba(5, 4, 11, 0.75) 0%, rgba(66, 54, 142, 0.75) 41.67%, rgba(248, 172, 255, 0.75) 100%)', // Background used in menus
      default: '#42368E',
      activeElement: 'linear-gradient(0deg, rgba(64, 183, 119, 0.15) 0%, #F8ACFF 100%)'
    },
    divider: 'rgba(255,255,255,0.49)',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'FuturaPT';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Futura PT Demi'), url('${FuturaPT}') format('woff2');
        }
        @font-face {
          font-family: 'FuturaPTBold';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Futura PT Bold'), url('${FuturaPTBold}') format('woff2');
        }
        @font-face {
          font-family: 'FuturaPTOblique';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Futura PT Demi Oblique'), url('${FuturaPTOblique}') format('woff2');
        }
        @font-face {
          font-family: 'FuturaPTBoldOblique';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Futura PT Extra Bold Oblique'), url('${FuturaPTBoldOblique}') format('woff2');
        }
      `,
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: 'white'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({theme}) => ({
          '&:after': {
            content:'""',
            opacity:.7,
            ...(theme.journeyBackground.lesson),
          }
        }),
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({theme}) => ({

          background: 'rgba(0, 0, 0, 0.6)'

        })

      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          background:
            'linear-gradient(263.33deg, #362C78 0.57%, #328EBB 53.05%, #40B777 102.35%)',
          border: '1.5px #b3b3b3 solid',
          borderRadius: '15px',
          boxShadow: '0px 4px 10px 2px rgba(64, 173, 226, 0.35)',
          color: 'white',
          height: 36,
          padding: '8px 16px',
          '&:hover': {
            borderColor: 'inherit',
            boxShadow: '0px 4px 20px 3px #40ADE2'
          },
          '&.Mui-disabled': {
            background:
              'linear-gradient(263.33deg, #362C78/80% 0.57%, #328EBB/80% 53.05%, #40B777/80% 102.35%)',
          }

        },
      },
    },
  },
  shape: {
    borderRadius: 16,
  },
  journeyBackground: {
    default: {
      backgroundImage:
        'linear-gradient(134deg, rgba(255, 243, 0, .14) 0%, rgba(0, 195, 255, 0.2) 50%, rgba(79, 0, 255, 0.34) 100%),' +
        'linear-gradient(202deg, rgba(40, 254, 254, 1) 0%, rgba(0, 113, 184, 1) 23%, rgba(180, 49, 171, 0.5) 60%, rgba(176, 57, 146, 1) 100%),' +
        `url(${BkgImage})`,
      backgroundSize: 'cover, cover, cover',
      backgroundBlendMode: 'color, color, normal',
    },
    lesson: {
      backgroundImage:
        'linear-gradient(180deg, rgba(5, 4, 11, 0.6) 0%, rgba(66, 54, 142, 0.6) 41.67%, rgba(248, 172, 255, 0.6) 100%),' +
        'linear-gradient(202deg, rgba(40, 254, 254, 1) 0%, rgba(0, 113, 184, 1) 23%, rgba(180, 49, 171, 0.5) 60%, rgba(176, 57, 146, 1) 100%),' +
        `url(${BkgImage})`,
      backgroundSize: '100vw 200vh, cover, cover',
      backgroundBlendMode: 'normal, color, normal',
    },
  },
};

const testThemeOptions: ThemeOptions = {
  ...themeOptions,
  palette: {
    mode: 'dark',
    primary: {
      main: '#06d066',
    },
    secondary: {
      main: '#ff761b',
    },
    contrast: {
      main: '#6c372d',
    },
    background: {
      // ...themeOptions.palette!.background,
      paper: 'rgba(0,0,0,0.4)',
      activeElement: //language=css prefix=".class{ background:" suffix=;}
        'linear-gradient(0deg, rgba(64, 183, 119, 0.15) 0%, #ffb375 100%)'

    },
  },
  journeyBackground: {
    // ...themeOptions.journeyBackground,
    default: {
      ...themeOptions.journeyBackground?.default,
      backgroundImage:
        'linear-gradient(134deg, rgba(173, 0, 38, .34) 0%, rgba(255, 254, 230, 0.16) 50%, rgba(231, 0, 255, 0.24) 100%),' +
        'linear-gradient(202deg, rgba(255, 202, 0,  1) 0%, rgba(255, 236, 4, 1) 23%, rgba(251,247,27, 0.9) 60%, rgba(255, 170, 0, 1) 100%),' +
        `url(${BkgImage})`,
      backgroundSize: 'cover, cover, cover',
      backgroundBlendMode: 'color, color, normal',

    },
    lesson: {
      backgroundImage: //language=css prefix=".class{ background:" suffix=;}
        'linear-gradient(180deg, rgb(11 9 4 / 60%) 0%, rgb(142 108 54 / 60%) 41.67%, rgb(255 207 172 / 60%) 100%),' +
        'linear-gradient(202deg, rgba(255, 202, 0,  1) 0%, rgba(255, 236, 4, 1) 23%, rgba(251,247,27, 0.9) 60%, rgba(255, 170, 0, 1) 100%),' +
        `url(${BkgImage})`,
      backgroundSize: '100% 200%, cover, cover',
      backgroundBlendMode: 'normal, color, normal',
    },

  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // ...(themeOptions.components!.MuiButton!.styleOverrides!.root as object),
          background: //language=css prefix=".class{ background:" suffix=;}
            'linear-gradient(263.33deg, rgb(120,116,44) 0.57%, rgb(156,187,50) 53.05%, rgb(183,147,64) 102.35%)',
          boxShadow: //language=css prefix=".class{ box-shadow:" suffix=;}
            '0px 4px 10px 2px rgb(226 194 64 / 35%)',
          color: 'white',
          height: 36,
          padding: '8px 16px',
          '&:hover': {
            borderColor: 'inherit',
            boxShadow://language=css prefix=".class{ box-shadow:" suffix=;}
              '0px 4px 20px 3px #dce240'
          },
          '&.Mui-disabled': {
            background: //language=css prefix=".class{ background:" suffix=;}
              'linear-gradient(263.33deg, rgba(120,116,44,0.7) 0.57%, rgba(156,187,50,.7) 53.05%, rgba(183,147,64,.7) 102.35%)',
          }

        },
      }
    }
  }
}
export const appTheme = createTheme(themeOptions)
export const appThemes: Record<string, Theme> = {
  default: appTheme,
  yellow: createTheme(deepmerge(themeOptions,  testThemeOptions))
}
