import { Box, Stack, Typography } from "@mui/material";
import GradientColorPicker, { ColorPickerProps } from "react-best-gradient-color-picker";

interface GradientPickerProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  description: string;
  showHexAlpha?: boolean;
}

const defaultPickerProps: Partial<ColorPickerProps> = {
  hideAdvancedSliders: false,
  hidePresets: true,
  hideGradientControls: true,
  hideEyeDrop: true,
  hideInputType: true,
  hideColorGuide: true,
  hidePickerSquare: true,
  hideGradientType: true,
  hideGradientAngle: true,
  hideColorTypeBtns: true,
};

export function GradientPicker({
  value,
  onChange,
  label,
  description,
  showHexAlpha = true,
}: GradientPickerProps) {
  return (
    <Box sx={{ width: "50%" }}>
      {label && <Typography>{label}</Typography>}
      <Typography variant="caption" color="text.secondary" sx={{ mb: 1, display: "block" }}>
        {description}
      </Typography>
      <GradientColorPicker
        {...defaultPickerProps}
        showHexAlpha={showHexAlpha}
        hideOpacity={!showHexAlpha}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
} 