export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** JSON describing InputAssessments */
  JrnyInputAssessments: { input: any; output: any; }
  /** JSON describing PromptInputSubmissions */
  JrnyPromptInputSubmissions: { input: any; output: any; }
};

export type IGQLCreateEmailLoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type IGQLEmailLogin = {
  __typename: 'EmailLogin';
  created: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  emailLowercase: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  status: Scalars['String']['output'];
  user: IGQLUser;
  verifiedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IGQLFileFolder = {
  __typename: 'FileFolder';
  created: Scalars['DateTime']['output'];
  fileObjects: Array<IGQLFileObject>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ownerId: Scalars['String']['output'];
  parent?: Maybe<IGQLFileFolder>;
  parentId?: Maybe<Scalars['String']['output']>;
};

export type IGQLFileObject = {
  __typename: 'FileObject';
  contentType: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  folder: IGQLFileFolder;
  folderId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originalName: Scalars['String']['output'];
  owner: IGQLFileOwner;
  ownerId: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  type?: Maybe<Scalars['String']['output']>;
  unconfirmed: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type IGQLFileOwner = {
  __typename: 'FileOwner';
  fileObjects: Array<IGQLFileObject>;
  folders: Array<IGQLFileFolder>;
  id: Scalars['ID']['output'];
  rootFolder: IGQLFileFolder;
  rootFolderId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export enum IGQLInvitationStatus {
  Accepted = 'ACCEPTED',
  Due = 'DUE',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Revoked = 'REVOKED'
}

export enum IGQLInvitationType {
  Organization = 'ORGANIZATION',
  Site = 'SITE'
}

export type IGQLJrnyAcceptInvitation = {
  __typename: 'JrnyAcceptInvitation';
  invitation: IGQLJrnyInvitation;
  loginResult: IGQLJrnyLoginResult;
};

export type IGQLJrnyAcceptSiteInvitationInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type IGQLJrnyAppCreateTeamInput = {
  members: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type IGQLJrnyAppCreateTeamsInput = {
  courseId: Scalars['ID']['input'];
  teams: Array<IGQLJrnyAppCreateTeamInput>;
};

export type IGQLJrnyAppInternalUser = {
  __typename: 'JrnyAppInternalUser';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  participations: Array<IGQLJrnyParticipation>;
};

export type IGQLJrnyAppLeaderBoardEntry = {
  __typename: 'JrnyAppLeaderBoardEntry';
  points: Scalars['Float']['output'];
  user: IGQLJrnyAppInternalUser;
};

export type IGQLJrnyAppLessonInput = {
  courseId: Scalars['ID']['input'];
  lessonId: Scalars['ID']['input'];
};

export type IGQLJrnyAppLoginInput = {
  idToken: Scalars['String']['input'];
};

export type IGQLJrnyAppLoginResult = {
  __typename: 'JrnyAppLoginResult';
  appUser: IGQLJrnyAppInternalUser;
  tokenResponse: IGQLTokenResponse;
};

export type IGQLJrnyAppNotification = {
  __typename: 'JrnyAppNotification';
  courseId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  readAt?: Maybe<Scalars['DateTime']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  type: IGQLJrnyAppNotificationType;
  userId: Scalars['ID']['output'];
};

export type IGQLJrnyAppNotificationInput = {
  courseId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export enum IGQLJrnyAppNotificationType {
  Balance = 'BALANCE',
  Feedback = 'FEEDBACK',
  LessonUnlocked = 'LESSON_UNLOCKED',
  PerkUnlocked = 'PERK_UNLOCKED',
  Test = 'TEST'
}

export type IGQLJrnyAppNotificationsInput = {
  courseId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type IGQLJrnyAppPerkInput = {
  courseId: Scalars['ID']['input'];
  perkId: Scalars['ID']['input'];
};

export type IGQLJrnyAppSubmitPromptAnswerInput = {
  courseId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  inputSubmissions: Scalars['JrnyPromptInputSubmissions']['input'];
  promptId: Scalars['ID']['input'];
};

export type IGQLJrnyAppSubmitPromptResult = {
  __typename: 'JrnyAppSubmitPromptResult';
  assessment: IGQLJrnyAssessment;
  courseUser: IGQLJrnyCourseUser;
};

export type IGQLJrnyAppUpdateAssessmentFeedbackInput = {
  courseId: Scalars['ID']['input'];
  feedback: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  submissionVersion: Scalars['Int']['input'];
};

export type IGQLJrnyAppUpdateAssessmentInput = {
  courseId: Scalars['ID']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  manualInputAssessments: Scalars['JrnyInputAssessments']['input'];
  submissionVersion: Scalars['Int']['input'];
};

export type IGQLJrnyAppUpdateAssessmentResult = {
  __typename: 'JrnyAppUpdateAssessmentResult';
  assessment: IGQLJrnyCourseAssessment;
  error?: Maybe<Scalars['String']['output']>;
};

export type IGQLJrnyAppUpdateNotificationInput = {
  courseId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type IGQLJrnyAppUpdateSubmissionPromptResult = {
  __typename: 'JrnyAppUpdateSubmissionPromptResult';
  assessment: IGQLJrnyAssessment;
  courseUser: IGQLJrnyCourseUser;
  error?: Maybe<Scalars['String']['output']>;
};

export type IGQLJrnyAssessment = {
  __typename: 'JrnyAssessment';
  courseId: Scalars['ID']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  files: Array<IGQLFileObject>;
  id: Scalars['ID']['output'];
  inputAssessments: Scalars['JrnyInputAssessments']['output'];
  isTeam: Scalars['Boolean']['output'];
  points: Scalars['Float']['output'];
  promptId: Scalars['ID']['output'];
  score: Scalars['Float']['output'];
  state: IGQLJrnyAssessmentState;
  submission: IGQLJrnySubmission;
};

export enum IGQLJrnyAssessmentState {
  AutoDone = 'AUTO_DONE',
  FullyManual = 'FULLY_MANUAL',
  ManualDone = 'MANUAL_DONE',
  PartlyManual = 'PARTLY_MANUAL'
}

export type IGQLJrnyAttendance = {
  __typename: 'JrnyAttendance';
  courseId: Scalars['ID']['output'];
  lateMinutes: Scalars['Int']['output'];
  occasionId: Scalars['ID']['output'];
  state: IGQLJrnyAttendanceState;
  userId: Scalars['ID']['output'];
};

export type IGQLJrnyAttendanceInput = {
  courseId: Scalars['ID']['input'];
  lateMinutes?: InputMaybe<Scalars['Int']['input']>;
  occasionId: Scalars['ID']['input'];
  state: IGQLJrnyAttendanceState;
  userId: Scalars['ID']['input'];
};

export enum IGQLJrnyAttendanceState {
  Absent = 'ABSENT',
  Excused = 'EXCUSED',
  Late = 'LATE',
  Present = 'PRESENT'
}

export type IGQLJrnyCheckpoint = {
  __typename: 'JrnyCheckpoint';
  coordinates: IGQLJrnyCoordinates;
  courseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lessons: Array<IGQLJrnyLesson>;
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
};

export type IGQLJrnyCloneCheckpointInput = {
  checkpointId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  targetCourseId: Scalars['ID']['input'];
  targetOrder: Scalars['Float']['input'];
};

export type IGQLJrnyCloneContentItemInput = {
  contentItemId: Scalars['ID']['input'];
  contentItemType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  targetLessonId: Scalars['String']['input'];
  targetOrder: Scalars['Float']['input'];
};

export type IGQLJrnyCloneCourseInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type IGQLJrnyCloneLessonInput = {
  lessonId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  targetCheckpointId: Scalars['String']['input'];
  targetOrder: Scalars['Float']['input'];
};

export type IGQLJrnyClonePerkInput = {
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type IGQLJrnyConfirmUploadSimpleInput = {
  ids: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type IGQLJrnyContentItem = IGQLJrnyMedia | IGQLJrnyPrompt | IGQLJrnyResource;

export type IGQLJrnyCoordinates = {
  __typename: 'JrnyCoordinates';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type IGQLJrnyCoordinatesInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type IGQLJrnyCourse = {
  __typename: 'JrnyCourse';
  available: Scalars['Boolean']['output'];
  checkpoints: Array<IGQLJrnyCheckpoint>;
  id: Scalars['ID']['output'];
  leaderBoardRows: Scalars['Int']['output'];
  mapImage?: Maybe<IGQLFileObject>;
  mapImageId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  settings: IGQLJrnyCourseSettings;
};

export type IGQLJrnyCourseAssessment = {
  __typename: 'JrnyCourseAssessment';
  appInternalUserId?: Maybe<Scalars['String']['output']>;
  courseId: Scalars['ID']['output'];
  courseUser?: Maybe<IGQLJrnyCourseUser>;
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputAssessments: Scalars['JrnyInputAssessments']['output'];
  lessonId: Scalars['ID']['output'];
  points: Scalars['Float']['output'];
  promptId: Scalars['ID']['output'];
  score: Scalars['Float']['output'];
  state: IGQLJrnyAssessmentState;
  submission: IGQLJrnySubmission;
  team?: Maybe<IGQLJrnyTeam>;
};

export type IGQLJrnyCourseOccasion = {
  __typename: 'JrnyCourseOccasion';
  courseId: Scalars['ID']['output'];
  date: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type IGQLJrnyCourseOccasionInput = {
  courseId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type IGQLJrnyCourseSettings = {
  __typename: 'JrnyCourseSettings';
  customThemeSettings: IGQLJrnyCustomThemeColorsV1;
  diamondName: Scalars['String']['output'];
  diamondNamePlural: Scalars['String']['output'];
  diamondSvg: Scalars['String']['output'];
  gemName: Scalars['String']['output'];
  gemNamePlural: Scalars['String']['output'];
  gemSvg: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  theme: Scalars['String']['output'];
  useCustomDiamond: Scalars['Boolean']['output'];
  useCustomGem: Scalars['Boolean']['output'];
};

export type IGQLJrnyCourseUser = {
  __typename: 'JrnyCourseUser';
  courseId: Scalars['ID']['output'];
  earnedPoints: Scalars['Float']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  role: Scalars['String']['output'];
  spentPoints: Scalars['Float']['output'];
  team?: Maybe<IGQLJrnyTeam>;
  userId: Scalars['ID']['output'];
};

export type IGQLJrnyCreateCheckpointInput = {
  coordinates?: InputMaybe<IGQLJrnyCoordinatesInput>;
  courseId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
};

export type IGQLJrnyCreateContentItemInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  contentItemType: Scalars['ID']['input'];
  lessonId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
};

export type IGQLJrnyCreateCourseInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type IGQLJrnyCreateCourseOccasionInput = {
  courseId: Scalars['ID']['input'];
  date: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
};

export type IGQLJrnyCreateCourseOccasionsInput = {
  courseId: Scalars['ID']['input'];
  dateDurations: Array<IGQLJrnyDateDurationInput>;
};

export type IGQLJrnyCreateFolderInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
};

export type IGQLJrnyCreateLessonInput = {
  checkpointId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
};

export type IGQLJrnyCreateMediaInput = {
  categoryOrder: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  iframe?: InputMaybe<Scalars['JSON']['input']>;
  imageFileId?: InputMaybe<Scalars['ID']['input']>;
  mediaType?: InputMaybe<IGQLJrnyMediaType>;
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  videoFileId?: InputMaybe<Scalars['ID']['input']>;
};

export type IGQLJrnyCreateMultipleParticipationsInput = {
  courseId: Scalars['ID']['input'];
  entries: Array<IGQLJrnyParticipationEntryInput>;
};

export type IGQLJrnyCreateOrganizationInput = {
  name: Scalars['String']['input'];
};

export type IGQLJrnyCreateParticipationInput = {
  courseId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type IGQLJrnyCreatePerkInput = {
  organizationId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type IGQLJrnyCreatePromptInput = {
  category: Scalars['String']['input'];
  categoryOrder: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type IGQLJrnyCreateResourceInput = {
  categoryOrder: Scalars['Int']['input'];
  fileObjectId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type IGQLJrnyCreateUpdateSettingsInput = {
  domain: Scalars['String']['input'];
  key: Scalars['String']['input'];
  /** Value */
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type IGQLJrnyCustomThemeColorsV1 = {
  __typename: 'JrnyCustomThemeColorsV1';
  backgroundGradient1?: Maybe<Scalars['String']['output']>;
  backgroundGradient2?: Maybe<Scalars['String']['output']>;
  buttonGradient?: Maybe<Scalars['String']['output']>;
  lessonGradient1?: Maybe<Scalars['String']['output']>;
  lessonGradient2?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
};

export type IGQLJrnyCustomThemeColorsV1Input = {
  backgroundGradient1?: InputMaybe<Scalars['String']['input']>;
  backgroundGradient2?: InputMaybe<Scalars['String']['input']>;
  buttonGradient?: InputMaybe<Scalars['String']['input']>;
  lessonGradient1?: InputMaybe<Scalars['String']['input']>;
  lessonGradient2?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type IGQLJrnyDateDurationInput = {
  date: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
};

export type IGQLJrnyDeleteFileInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type IGQLJrnyDeleteFolderInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type IGQLJrnyInvitation = {
  __typename: 'JrnyInvitation';
  created: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['ID']['output']>;
  status: IGQLInvitationStatus;
  type: IGQLInvitationType;
  validTo: Scalars['DateTime']['output'];
};

export type IGQLJrnyInviteUserToOrganizationInput = {
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type IGQLJrnyLesson = {
  __typename: 'JrnyLesson';
  checkpointId: Scalars['ID']['output'];
  contentItems: Array<IGQLJrnyContentItem>;
  id: Scalars['ID']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  summary: Scalars['String']['output'];
  teacherNotes?: Maybe<Scalars['String']['output']>;
  version: Scalars['Float']['output'];
};

export type IGQLJrnyLoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type IGQLJrnyLoginResult = {
  __typename: 'JrnyLoginResult';
  organizationIds: Array<Scalars['String']['output']>;
  permissions: Array<Scalars['String']['output']>;
  tokenResponse: IGQLTokenResponse;
  user: IGQLJrnyUser;
};

export type IGQLJrnyMedia = {
  __typename: 'JrnyMedia';
  category: Scalars['String']['output'];
  categoryOrder: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  iframe?: Maybe<Scalars['JSON']['output']>;
  image?: Maybe<IGQLFileObject>;
  lessonId: Scalars['ID']['output'];
  mediaType: IGQLJrnyMediaType;
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  video?: Maybe<IGQLFileObject>;
};

export enum IGQLJrnyMediaType {
  Iframe = 'IFRAME',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type IGQLJrnyMoveCheckpointInput = {
  checkpointId: Scalars['ID']['input'];
  sourceCourseId: Scalars['ID']['input'];
  targetCourseId: Scalars['ID']['input'];
  targetOrder: Scalars['Float']['input'];
};

export type IGQLJrnyMoveCheckpointPayload = {
  __typename: 'JrnyMoveCheckpointPayload';
  source: IGQLJrnyCourse;
  target: IGQLJrnyCourse;
};

export type IGQLJrnyMoveContentItemInput = {
  contentItemId: Scalars['ID']['input'];
  contentItemType: Scalars['String']['input'];
  sourceLessonId: Scalars['String']['input'];
  targetLessonId: Scalars['String']['input'];
  targetOrder: Scalars['Float']['input'];
};

export type IGQLJrnyMoveContentItemPayload = {
  __typename: 'JrnyMoveContentItemPayload';
  source: IGQLJrnyLesson;
  target: IGQLJrnyLesson;
};

export type IGQLJrnyMoveFileInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  sourceFolderId: Scalars['ID']['input'];
  targetFolderId: Scalars['ID']['input'];
};

export type IGQLJrnyMoveFilePayload = {
  __typename: 'JrnyMoveFilePayload';
  fileObject: IGQLFileObject;
  source: IGQLFileFolder;
  target: IGQLFileFolder;
};

export type IGQLJrnyMoveFolderInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  sourceFolderId: Scalars['ID']['input'];
  targetFolderId: Scalars['ID']['input'];
};

export type IGQLJrnyMoveLessonInput = {
  lessonId: Scalars['ID']['input'];
  sourceCheckpointId: Scalars['String']['input'];
  targetCheckpointId: Scalars['String']['input'];
  targetOrder: Scalars['Float']['input'];
};

export type IGQLJrnyMoveLessonPayload = {
  __typename: 'JrnyMoveLessonPayload';
  source: IGQLJrnyCheckpoint;
  target: IGQLJrnyCheckpoint;
};

export type IGQLJrnyOrganization = {
  __typename: 'JrnyOrganization';
  courses: Array<IGQLJrnyCourse>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  perks: Array<IGQLJrnyPerk>;
};

export type IGQLJrnyOrganizationFilesInput = {
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type IGQLJrnyOrganizationFoldersInput = {
  id: Scalars['ID']['input'];
};

export type IGQLJrnyOrganizationUser = {
  __typename: 'JrnyOrganizationUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type IGQLJrnyParticipation = {
  __typename: 'JrnyParticipation';
  course: IGQLJrnyCourse;
  courseId: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: IGQLJrnyAppInternalUser;
  userId: Scalars['ID']['output'];
};

export type IGQLJrnyParticipationEntryInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type IGQLJrnyPerk = {
  __typename: 'JrnyPerk';
  created: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<IGQLFileObject>;
  inStockCount: Scalars['Int']['output'];
  isOutOfStock: Scalars['Boolean']['output'];
  managerEmail: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  perkCourses: Array<Scalars['ID']['output']>;
  price: Scalars['Float']['output'];
  providerEmail: Scalars['String']['output'];
  purchases: Array<IGQLJrnyPerkPurchase>;
  title: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type IGQLJrnyPerkBuyer = {
  __typename: 'JrnyPerkBuyer';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isTeam: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type IGQLJrnyPerkPurchase = {
  __typename: 'JrnyPerkPurchase';
  buyer: IGQLJrnyPerkBuyer;
  courseId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  perk: IGQLJrnyPerk;
  price: Scalars['Float']['output'];
};

export type IGQLJrnyPrompt = {
  __typename: 'JrnyPrompt';
  category: Scalars['String']['output'];
  categoryOrder: Scalars['Int']['output'];
  correctPoints: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  inputs: Array<IGQLJrnyPromptInput>;
  isTeam: Scalars['Boolean']['output'];
  lessonId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  requireAnyInputs: Scalars['Boolean']['output'];
  submitPoints: Scalars['Float']['output'];
  useAIAssessment: Scalars['Boolean']['output'];
};

export type IGQLJrnyPromptInput = {
  __typename: 'JrnyPromptInput';
  config: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  minContribution: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  version: Scalars['Int']['output'];
  weight: Scalars['Float']['output'];
};

export type IGQLJrnyPromptInputInput = {
  config: Scalars['JSON']['input'];
  id: Scalars['ID']['input'];
  minContribution: Scalars['Float']['input'];
  type: Scalars['String']['input'];
  version: Scalars['Int']['input'];
  weight: Scalars['Float']['input'];
};

export type IGQLJrnyRemoveCheckpointInput = {
  courseId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type IGQLJrnyRemoveContentItemInput = {
  contentItemType: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lessonId: Scalars['ID']['input'];
};

export type IGQLJrnyRemoveLessonInput = {
  checkpointId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type IGQLJrnyRemoveParticipationInput = {
  courseId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type IGQLJrnyRenameFileObjectInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type IGQLJrnyRenameFolderInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type IGQLJrnyResetPasswordInput = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type IGQLJrnyResource = {
  __typename: 'JrnyResource';
  category: Scalars['String']['output'];
  categoryOrder: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  file?: Maybe<IGQLFileObject>;
  id: Scalars['ID']['output'];
  lessonId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  type: IGQLJrnyResourceType;
  url?: Maybe<Scalars['String']['output']>;
};

export enum IGQLJrnyResourceType {
  File = 'FILE',
  Url = 'URL'
}

export type IGQLJrnySendEmailToParticipantsInput = {
  courseId: Scalars['ID']['input'];
  template?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['ID']['input']>;
};

export type IGQLJrnySendEmailToParticipantsOutput = {
  __typename: 'JrnySendEmailToParticipantsOutput';
  courseId: Scalars['ID']['output'];
  userIds: Array<Scalars['ID']['output']>;
};

export type IGQLJrnySettings = {
  __typename: 'JrnySettings';
  /** Domain of settings */
  domain: Scalars['String']['output'];
  /** Key in domain */
  key: Scalars['String']['output'];
  /** Value of field */
  value?: Maybe<Scalars['JSON']['output']>;
};

export type IGQLJrnySettingsIdInput = {
  domain: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type IGQLJrnySubmission = {
  __typename: 'JrnySubmission';
  id: Scalars['ID']['output'];
  inputSubmissions: Scalars['JrnyPromptInputSubmissions']['output'];
  promptId: Scalars['ID']['output'];
  version: Scalars['Int']['output'];
};

export type IGQLJrnyTeam = {
  __typename: 'JrnyTeam';
  id: Scalars['ID']['output'];
  members: Array<IGQLJrnyAppInternalUser>;
  name: Scalars['String']['output'];
  points: Scalars['Float']['output'];
};

export type IGQLJrnyUnsetAttendanceInput = {
  courseId: Scalars['ID']['input'];
  occasionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type IGQLJrnyUnsetAttendancePayload = {
  __typename: 'JrnyUnsetAttendancePayload';
  courseId: Scalars['ID']['output'];
  occasionId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type IGQLJrnyUpdateCheckpointInput = {
  coordinates?: InputMaybe<IGQLJrnyCoordinatesInput>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  lessonsAdded?: InputMaybe<Array<IGQLJrnyCreateLessonInput>>;
  lessonsDeleted?: InputMaybe<Array<IGQLJrnyUpdateLessonInput>>;
  lessonsModified?: InputMaybe<Array<IGQLJrnyUpdateLessonInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type IGQLJrnyUpdateCourseInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  checkpointsAdded?: InputMaybe<Array<IGQLJrnyCreateCheckpointInput>>;
  checkpointsDeleted?: InputMaybe<Array<IGQLJrnyUpdateCheckpointInput>>;
  checkpointsModified?: InputMaybe<Array<IGQLJrnyUpdateCheckpointInput>>;
  id: Scalars['ID']['input'];
  leaderBoardRows?: InputMaybe<Scalars['Float']['input']>;
  mapImageId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IGQLJrnyUpdateCourseOccasionInput = {
  courseId: Scalars['ID']['input'];
  date: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
};

export type IGQLJrnyUpdateCourseSettingsInput = {
  customThemeSettings: IGQLJrnyCustomThemeColorsV1Input;
  diamondName: Scalars['String']['input'];
  diamondNamePlural: Scalars['String']['input'];
  diamondSvg: Scalars['String']['input'];
  gemName: Scalars['String']['input'];
  gemNamePlural: Scalars['String']['input'];
  gemSvg: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  theme: Scalars['String']['input'];
  useCustomDiamond: Scalars['Boolean']['input'];
  useCustomGem: Scalars['Boolean']['input'];
};

export type IGQLJrnyUpdateLessonInput = {
  id: Scalars['ID']['input'];
  mediaAdded?: InputMaybe<Array<IGQLJrnyCreateMediaInput>>;
  mediaDeleted?: InputMaybe<Array<IGQLJrnyUpdateMediaInput>>;
  mediaModified?: InputMaybe<Array<IGQLJrnyUpdateMediaInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
  promptsAdded?: InputMaybe<Array<IGQLJrnyCreatePromptInput>>;
  promptsDeleted?: InputMaybe<Array<IGQLJrnyUpdatePromptInput>>;
  promptsModified?: InputMaybe<Array<IGQLJrnyUpdatePromptInput>>;
  resourcesAdded?: InputMaybe<Array<IGQLJrnyCreateResourceInput>>;
  resourcesDeleted?: InputMaybe<Array<IGQLJrnyUpdateResourceInput>>;
  resourcesModified?: InputMaybe<Array<IGQLJrnyUpdateResourceInput>>;
  summary?: InputMaybe<Scalars['String']['input']>;
  teacherNotes?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Float']['input']>;
};

export type IGQLJrnyUpdateMediaInput = {
  categoryOrder?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  iframe?: InputMaybe<Scalars['JSON']['input']>;
  imageFileId?: InputMaybe<Scalars['ID']['input']>;
  mediaType?: InputMaybe<IGQLJrnyMediaType>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  videoFileId?: InputMaybe<Scalars['ID']['input']>;
};

export type IGQLJrnyUpdateOrganizationInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IGQLJrnyUpdatePerkInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageId?: InputMaybe<Scalars['String']['input']>;
  inStockCount?: InputMaybe<Scalars['Int']['input']>;
  managerEmail?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  perkCourses?: InputMaybe<Array<Scalars['ID']['input']>>;
  price?: InputMaybe<Scalars['Float']['input']>;
  providerEmail?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IGQLJrnyUpdatePromptInput = {
  categoryOrder?: InputMaybe<Scalars['Int']['input']>;
  correctPoints?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  inputs?: InputMaybe<Array<IGQLJrnyPromptInputInput>>;
  isTeam?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  requireAnyInputs?: InputMaybe<Scalars['Boolean']['input']>;
  submitPoints?: InputMaybe<Scalars['Float']['input']>;
  useAIAssessment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IGQLJrnyUpdateResourceInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  categoryOrder?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<IGQLJrnyResourceType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type IGQLJrnyUpdateTeamInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IGQLJrnyUser = {
  __typename: 'JrnyUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type IGQLMutation = {
  __typename: 'Mutation';
  addUserRole: IGQLUser;
  createEmailLogin: IGQLEmailLogin;
  createFileOwner: IGQLFileOwner;
  createRole: IGQLRole;
  deleteFileObject: IGQLFileObject;
  deleteRole: Scalars['ID']['output'];
  jrnyAcceptInvitation: IGQLJrnyAcceptInvitation;
  jrnyAcceptSiteInvitation: IGQLJrnyLoginResult;
  jrnyAppCreateCourseTeams: Array<IGQLJrnyTeam>;
  jrnyAppLesson: IGQLJrnyLesson;
  jrnyAppLockLesson: IGQLJrnyLesson;
  jrnyAppLogin: IGQLJrnyAppLoginResult;
  jrnyAppMyAssessment: IGQLJrnyAssessment;
  jrnyAppNotificationShim: IGQLJrnyAppNotification;
  jrnyAppPerkShim: IGQLJrnyPerk;
  jrnyAppPurchasePerk: IGQLJrnyPerkPurchase;
  jrnyAppSaveAttendance: IGQLJrnyAttendance;
  jrnyAppSubmitPromptAnswer: IGQLJrnyAppSubmitPromptResult;
  jrnyAppUnlockLesson: IGQLJrnyLesson;
  jrnyAppUnsetAttendance: IGQLJrnyUnsetAttendancePayload;
  jrnyAppUpdateAssessment: IGQLJrnyAppUpdateAssessmentResult;
  jrnyAppUpdateAssessmentFeedback: IGQLJrnyAppUpdateAssessmentResult;
  jrnyAppUpdateNotification: IGQLJrnyAppNotification;
  jrnyAppUpdatePromptAnswer: IGQLJrnyAppUpdateSubmissionPromptResult;
  jrnyCloneCheckpoint: IGQLJrnyCourse;
  jrnyCloneContentItem: IGQLJrnyLesson;
  jrnyCloneCourse: IGQLJrnyCourse;
  jrnyCloneLesson: IGQLJrnyCheckpoint;
  jrnyClonePerk: IGQLJrnyPerk;
  jrnyCreateCheckpoint: IGQLJrnyCourse;
  jrnyCreateContentItem: IGQLJrnyLesson;
  jrnyCreateCourse: IGQLJrnyCourse;
  jrnyCreateCourseOccasion: IGQLJrnyCourseOccasion;
  jrnyCreateCourseOccasions: Array<IGQLJrnyCourseOccasion>;
  jrnyCreateFolder: IGQLFileFolder;
  jrnyCreateLesson: IGQLJrnyCheckpoint;
  jrnyCreateMultipleParticipations: Array<IGQLJrnyParticipation>;
  jrnyCreateOrganization: IGQLJrnyOrganization;
  jrnyCreateParticipation: IGQLJrnyParticipation;
  jrnyCreatePerk: IGQLJrnyPerk;
  jrnyDeleteFile: IGQLFileObject;
  jrnyDeleteFolder: IGQLFileFolder;
  jrnyInviteUserToOrganization: IGQLJrnyInvitation;
  jrnyLogin: IGQLJrnyLoginResult;
  jrnyMoveCheckpoint: IGQLJrnyMoveCheckpointPayload;
  jrnyMoveContentItem: IGQLJrnyMoveContentItemPayload;
  jrnyMoveFile: IGQLJrnyMoveFilePayload;
  jrnyMoveFolder: IGQLFileFolder;
  jrnyMoveLesson: IGQLJrnyMoveLessonPayload;
  jrnyRejectInvitation: IGQLJrnyInvitation;
  jrnyRemoveCheckpoint: IGQLJrnyCourse;
  jrnyRemoveContentItem: IGQLJrnyLesson;
  jrnyRemoveCourse: IGQLJrnyCourse;
  jrnyRemoveCourseOccasion: IGQLJrnyCourseOccasion;
  jrnyRemoveLesson: IGQLJrnyCheckpoint;
  jrnyRemoveParticipation: IGQLJrnyParticipation;
  jrnyRemovePerk: IGQLJrnyPerk;
  jrnyRemoveSettings: IGQLJrnySettings;
  jrnyRemoveTeam: IGQLJrnyTeam;
  jrnyRenameFolder: IGQLFileFolder;
  jrnyRequestResetPassword: Scalars['String']['output'];
  jrnyResendInvitation: IGQLJrnyInvitation;
  jrnyResetPassword: IGQLEmailLogin;
  jrnyRevokeInvitation: IGQLJrnyInvitation;
  jrnySaveSettings: IGQLJrnySettings;
  jrnySendEmailToParticipants: IGQLJrnySendEmailToParticipantsOutput;
  jrnyUpdateCheckpoint: IGQLJrnyCheckpoint;
  jrnyUpdateCourse: IGQLJrnyCourse;
  jrnyUpdateCourseOccasion: IGQLJrnyCourseOccasion;
  jrnyUpdateCourseSettings: IGQLJrnyCourseSettings;
  jrnyUpdateLesson: IGQLJrnyLesson;
  jrnyUpdateOrganization: IGQLJrnyOrganization;
  jrnyUpdatePerk: IGQLJrnyPerk;
  jrnyUpdatePrompt: IGQLJrnyPrompt;
  jrnyUpdateResource: IGQLJrnyResource;
  jrnyUpdateTeam: IGQLJrnyTeam;
  jrnyUpdateVideo: IGQLJrnyMedia;
  removeUserRole: IGQLUser;
  renameFileObject: IGQLFileObject;
  updateRole: IGQLRole;
  updateUserRoles: IGQLUser;
  uploadsConfirmSimple: Array<IGQLFileObject>;
};


export type IGQLMutationAddUserRoleArgs = {
  input: IGQLUserRoleInput;
};


export type IGQLMutationCreateEmailLoginArgs = {
  input: IGQLCreateEmailLoginInput;
};


export type IGQLMutationCreateFileOwnerArgs = {
  type: Scalars['String']['input'];
};


export type IGQLMutationCreateRoleArgs = {
  input: IGQLRoleInput;
};


export type IGQLMutationDeleteFileObjectArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLMutationDeleteRoleArgs = {
  name: Scalars['String']['input'];
};


export type IGQLMutationJrnyAcceptInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type IGQLMutationJrnyAcceptSiteInvitationArgs = {
  input: IGQLJrnyAcceptSiteInvitationInput;
};


export type IGQLMutationJrnyAppCreateCourseTeamsArgs = {
  input: IGQLJrnyAppCreateTeamsInput;
};


export type IGQLMutationJrnyAppLessonArgs = {
  input: IGQLJrnyAppLessonInput;
};


export type IGQLMutationJrnyAppLockLessonArgs = {
  input: IGQLJrnyAppLessonInput;
};


export type IGQLMutationJrnyAppLoginArgs = {
  input: IGQLJrnyAppLoginInput;
};


export type IGQLMutationJrnyAppMyAssessmentArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLMutationJrnyAppNotificationShimArgs = {
  input: IGQLJrnyAppNotificationInput;
};


export type IGQLMutationJrnyAppPerkShimArgs = {
  input: IGQLJrnyAppPerkInput;
};


export type IGQLMutationJrnyAppPurchasePerkArgs = {
  input: IGQLJrnyAppPerkInput;
};


export type IGQLMutationJrnyAppSaveAttendanceArgs = {
  input: IGQLJrnyAttendanceInput;
};


export type IGQLMutationJrnyAppSubmitPromptAnswerArgs = {
  input: IGQLJrnyAppSubmitPromptAnswerInput;
};


export type IGQLMutationJrnyAppUnlockLessonArgs = {
  input: IGQLJrnyAppLessonInput;
};


export type IGQLMutationJrnyAppUnsetAttendanceArgs = {
  input: IGQLJrnyUnsetAttendanceInput;
};


export type IGQLMutationJrnyAppUpdateAssessmentArgs = {
  input: IGQLJrnyAppUpdateAssessmentInput;
};


export type IGQLMutationJrnyAppUpdateAssessmentFeedbackArgs = {
  input: IGQLJrnyAppUpdateAssessmentFeedbackInput;
};


export type IGQLMutationJrnyAppUpdateNotificationArgs = {
  input: IGQLJrnyAppUpdateNotificationInput;
};


export type IGQLMutationJrnyAppUpdatePromptAnswerArgs = {
  input: IGQLJrnyAppSubmitPromptAnswerInput;
};


export type IGQLMutationJrnyCloneCheckpointArgs = {
  input: IGQLJrnyCloneCheckpointInput;
};


export type IGQLMutationJrnyCloneContentItemArgs = {
  input: IGQLJrnyCloneContentItemInput;
};


export type IGQLMutationJrnyCloneCourseArgs = {
  input: IGQLJrnyCloneCourseInput;
};


export type IGQLMutationJrnyCloneLessonArgs = {
  input: IGQLJrnyCloneLessonInput;
};


export type IGQLMutationJrnyClonePerkArgs = {
  input: IGQLJrnyClonePerkInput;
};


export type IGQLMutationJrnyCreateCheckpointArgs = {
  input: IGQLJrnyCreateCheckpointInput;
};


export type IGQLMutationJrnyCreateContentItemArgs = {
  input: IGQLJrnyCreateContentItemInput;
};


export type IGQLMutationJrnyCreateCourseArgs = {
  input: IGQLJrnyCreateCourseInput;
};


export type IGQLMutationJrnyCreateCourseOccasionArgs = {
  input: IGQLJrnyCreateCourseOccasionInput;
};


export type IGQLMutationJrnyCreateCourseOccasionsArgs = {
  input: IGQLJrnyCreateCourseOccasionsInput;
};


export type IGQLMutationJrnyCreateFolderArgs = {
  input: IGQLJrnyCreateFolderInput;
};


export type IGQLMutationJrnyCreateLessonArgs = {
  input: IGQLJrnyCreateLessonInput;
};


export type IGQLMutationJrnyCreateMultipleParticipationsArgs = {
  input: IGQLJrnyCreateMultipleParticipationsInput;
};


export type IGQLMutationJrnyCreateOrganizationArgs = {
  input: IGQLJrnyCreateOrganizationInput;
};


export type IGQLMutationJrnyCreateParticipationArgs = {
  input: IGQLJrnyCreateParticipationInput;
};


export type IGQLMutationJrnyCreatePerkArgs = {
  input: IGQLJrnyCreatePerkInput;
};


export type IGQLMutationJrnyDeleteFileArgs = {
  input: IGQLJrnyDeleteFileInput;
};


export type IGQLMutationJrnyDeleteFolderArgs = {
  input: IGQLJrnyDeleteFolderInput;
};


export type IGQLMutationJrnyInviteUserToOrganizationArgs = {
  input: IGQLJrnyInviteUserToOrganizationInput;
};


export type IGQLMutationJrnyLoginArgs = {
  input: IGQLJrnyLoginInput;
};


export type IGQLMutationJrnyMoveCheckpointArgs = {
  input: IGQLJrnyMoveCheckpointInput;
};


export type IGQLMutationJrnyMoveContentItemArgs = {
  input: IGQLJrnyMoveContentItemInput;
};


export type IGQLMutationJrnyMoveFileArgs = {
  input: IGQLJrnyMoveFileInput;
};


export type IGQLMutationJrnyMoveFolderArgs = {
  input: IGQLJrnyMoveFolderInput;
};


export type IGQLMutationJrnyMoveLessonArgs = {
  input: IGQLJrnyMoveLessonInput;
};


export type IGQLMutationJrnyRejectInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type IGQLMutationJrnyRemoveCheckpointArgs = {
  input: IGQLJrnyRemoveCheckpointInput;
};


export type IGQLMutationJrnyRemoveContentItemArgs = {
  input: IGQLJrnyRemoveContentItemInput;
};


export type IGQLMutationJrnyRemoveCourseArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLMutationJrnyRemoveCourseOccasionArgs = {
  input: IGQLJrnyCourseOccasionInput;
};


export type IGQLMutationJrnyRemoveLessonArgs = {
  input: IGQLJrnyRemoveLessonInput;
};


export type IGQLMutationJrnyRemoveParticipationArgs = {
  input: IGQLJrnyRemoveParticipationInput;
};


export type IGQLMutationJrnyRemovePerkArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLMutationJrnyRemoveSettingsArgs = {
  id: IGQLJrnySettingsIdInput;
};


export type IGQLMutationJrnyRemoveTeamArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLMutationJrnyRenameFolderArgs = {
  input: IGQLJrnyRenameFolderInput;
};


export type IGQLMutationJrnyRequestResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type IGQLMutationJrnyResendInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type IGQLMutationJrnyResetPasswordArgs = {
  input: IGQLJrnyResetPasswordInput;
};


export type IGQLMutationJrnyRevokeInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type IGQLMutationJrnySaveSettingsArgs = {
  input: IGQLJrnyCreateUpdateSettingsInput;
};


export type IGQLMutationJrnySendEmailToParticipantsArgs = {
  input: IGQLJrnySendEmailToParticipantsInput;
};


export type IGQLMutationJrnyUpdateCheckpointArgs = {
  input: IGQLJrnyUpdateCheckpointInput;
};


export type IGQLMutationJrnyUpdateCourseArgs = {
  input: IGQLJrnyUpdateCourseInput;
};


export type IGQLMutationJrnyUpdateCourseOccasionArgs = {
  input: IGQLJrnyUpdateCourseOccasionInput;
};


export type IGQLMutationJrnyUpdateCourseSettingsArgs = {
  input: IGQLJrnyUpdateCourseSettingsInput;
};


export type IGQLMutationJrnyUpdateLessonArgs = {
  input: IGQLJrnyUpdateLessonInput;
};


export type IGQLMutationJrnyUpdateOrganizationArgs = {
  input: IGQLJrnyUpdateOrganizationInput;
};


export type IGQLMutationJrnyUpdatePerkArgs = {
  input: IGQLJrnyUpdatePerkInput;
};


export type IGQLMutationJrnyUpdatePromptArgs = {
  input: IGQLJrnyUpdatePromptInput;
};


export type IGQLMutationJrnyUpdateResourceArgs = {
  input: IGQLJrnyUpdateResourceInput;
};


export type IGQLMutationJrnyUpdateTeamArgs = {
  input: IGQLJrnyUpdateTeamInput;
};


export type IGQLMutationJrnyUpdateVideoArgs = {
  input: IGQLJrnyUpdateMediaInput;
};


export type IGQLMutationRemoveUserRoleArgs = {
  input: IGQLUserRoleInput;
};


export type IGQLMutationRenameFileObjectArgs = {
  input: IGQLJrnyRenameFileObjectInput;
};


export type IGQLMutationUpdateRoleArgs = {
  input: IGQLRoleInput;
};


export type IGQLMutationUpdateUserRolesArgs = {
  input: IGQLUpdateUserRolesInput;
};


export type IGQLMutationUploadsConfirmSimpleArgs = {
  input: IGQLJrnyConfirmUploadSimpleInput;
};

export type IGQLQuery = {
  __typename: 'Query';
  emailLogin: IGQLEmailLogin;
  emailLogins: Array<IGQLEmailLogin>;
  fileObject: IGQLFileObject;
  fileOwner: IGQLFileOwner;
  fileOwners: Array<IGQLFileOwner>;
  jrnyAppAttendance: Array<IGQLJrnyAttendance>;
  jrnyAppCourse: IGQLJrnyCourse;
  jrnyAppCourseAssessments: Array<IGQLJrnyCourseAssessment>;
  jrnyAppCourseLeaderBoard: Array<IGQLJrnyAppLeaderBoardEntry>;
  jrnyAppCourseTeams: Array<IGQLJrnyTeam>;
  jrnyAppCourseUsers: Array<IGQLJrnyCourseUser>;
  jrnyAppCurrentCourseUser: IGQLJrnyCourseUser;
  jrnyAppMyAssessments: Array<IGQLJrnyAssessment>;
  jrnyAppNotifications: Array<IGQLJrnyAppNotification>;
  jrnyAppPerks: Array<IGQLJrnyPerk>;
  jrnyAppPerksPurchased: Array<IGQLJrnyPerk>;
  jrnyCheckpoint: IGQLJrnyCheckpoint;
  jrnyCourse: IGQLJrnyCourse;
  jrnyCourseOccasion: IGQLJrnyCourseOccasion;
  jrnyCourseOccasionsForCourse: Array<IGQLJrnyCourseOccasion>;
  jrnyCourseSettings: IGQLJrnyCourseSettings;
  jrnyLesson: IGQLJrnyLesson;
  jrnyMedia: IGQLJrnyMedia;
  jrnyOrganization: IGQLJrnyOrganization;
  jrnyOrganizationFiles: Array<IGQLFileObject>;
  jrnyOrganizationFolders: Array<IGQLFileFolder>;
  jrnyOrganizationInvitations: Array<IGQLJrnyInvitation>;
  jrnyOrganizationUsers: Array<IGQLJrnyOrganizationUser>;
  jrnyOrganizations: Array<IGQLJrnyOrganization>;
  jrnyParticipationsForCourse: Array<IGQLJrnyParticipation>;
  jrnyPerk: IGQLJrnyPerk;
  jrnyPerks: Array<IGQLJrnyPerk>;
  jrnyPrompt: IGQLJrnyPrompt;
  jrnyResource: IGQLJrnyResource;
  jrnySettings: Array<IGQLJrnySettings>;
  jrnySettingsById: IGQLJrnySettings;
  jrnySettingsForDomain: Array<IGQLJrnySettings>;
  jrnyTeam: IGQLJrnyTeam;
  jrnyTeams: Array<IGQLJrnyTeam>;
  jrnyUserInvitations: Array<IGQLJrnyInvitation>;
  role: IGQLRole;
  roles: Array<IGQLRole>;
  testPermission: Scalars['String']['output'];
  user: IGQLUser;
  users: Array<IGQLUser>;
};


export type IGQLQueryEmailLoginArgs = {
  email: Scalars['String']['input'];
};


export type IGQLQueryFileObjectArgs = {
  id: Scalars['String']['input'];
};


export type IGQLQueryFileOwnerArgs = {
  id: Scalars['String']['input'];
};


export type IGQLQueryJrnyAppAttendanceArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyAppCourseArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyAppCourseAssessmentsArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyAppCourseLeaderBoardArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyAppCourseTeamsArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyAppCourseUsersArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyAppCurrentCourseUserArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyAppMyAssessmentsArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyAppNotificationsArgs = {
  input: IGQLJrnyAppNotificationsInput;
};


export type IGQLQueryJrnyAppPerksArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyAppPerksPurchasedArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyCheckpointArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyCourseArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyCourseOccasionArgs = {
  input: IGQLJrnyCourseOccasionInput;
};


export type IGQLQueryJrnyCourseOccasionsForCourseArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyCourseSettingsArgs = {
  courseId: Scalars['String']['input'];
};


export type IGQLQueryJrnyLessonArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyMediaArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyOrganizationFilesArgs = {
  input: IGQLJrnyOrganizationFilesInput;
};


export type IGQLQueryJrnyOrganizationFoldersArgs = {
  input: IGQLJrnyOrganizationFoldersInput;
};


export type IGQLQueryJrnyOrganizationInvitationsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyOrganizationUsersArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyParticipationsForCourseArgs = {
  courseId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyPerkArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyPerksArgs = {
  organizationId: Scalars['ID']['input'];
};


export type IGQLQueryJrnyPromptArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyResourceArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnySettingsByIdArgs = {
  id: IGQLJrnySettingsIdInput;
};


export type IGQLQueryJrnySettingsForDomainArgs = {
  domain: Scalars['String']['input'];
};


export type IGQLQueryJrnyTeamArgs = {
  id: Scalars['ID']['input'];
};


export type IGQLQueryJrnyUserInvitationsArgs = {
  userId: Scalars['ID']['input'];
};


export type IGQLQueryRoleArgs = {
  id: Scalars['String']['input'];
  some: Scalars['String']['input'];
};


export type IGQLQueryUserArgs = {
  id: Scalars['String']['input'];
};

export type IGQLRole = {
  __typename: 'Role';
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['ID']['output'];
  permissions: Array<Scalars['String']['output']>;
};

export type IGQLRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissions: Array<Scalars['String']['input']>;
};

export type IGQLTokenResponse = {
  __typename: 'TokenResponse';
  refreshToken: Scalars['String']['output'];
  refreshTokenValidTo: Scalars['Float']['output'];
  token: Scalars['String']['output'];
  tokenValidTo: Scalars['Float']['output'];
};

export type IGQLUpdateUserRolesInput = {
  roles: Array<Scalars['ID']['input']>;
  userId: Scalars['String']['input'];
};

export type IGQLUser = {
  __typename: 'User';
  created: Scalars['DateTime']['output'];
  createdType: Scalars['String']['output'];
  deleted: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  roles: Array<IGQLRole>;
};

export type IGQLUserRoleInput = {
  role: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
