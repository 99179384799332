import { Box, CssBaseline, ThemeProvider, useTheme, createTheme } from "@mui/material";
import BkgImage from "@/assets/journey-bg-bw.jpg";
import { appTheme } from "../app-theme";
import { AppBackground } from "./AppBackground";
import { deepmerge } from "@mui/utils";

interface AppViewProps {
  gradient1: string;
  gradient2: string;
  isLesson?: boolean;
  sx?: any;
  children?: React.ReactNode;
  primaryColor?: string;
  secondaryColor?: string;
}

function AppViewContent({
  gradient1,
  gradient2,
  isLesson,
  sx = {},
  children
}: AppViewProps) {
  const theme = useTheme();
  
  return (
    <AppBackground
      gradient1={gradient1}
      gradient2={gradient2}
      isLesson={isLesson}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 10,
        ...sx
      }}
    >
      {children}
    </AppBackground>
  );
}

export function AppView({ primaryColor, secondaryColor, ...props }: AppViewProps) {
  const themeOverrides = {
    palette: {
      primary: primaryColor ? {
        main: primaryColor
      } : undefined,
      secondary: secondaryColor ? {
        main: secondaryColor
      } : undefined
    }
  };

  const theme = createTheme(deepmerge(appTheme, themeOverrides));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppViewContent {...props} />
    </ThemeProvider>
  );
} 