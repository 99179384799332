import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { FileObjectFieldsFragmentDoc } from './fragment-FileObject-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLCourseQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLCourseQuery = { __typename: 'Query', jrnyCourse: { __typename: 'JrnyCourse', organizationId: string, id: string, name: string, leaderBoardRows: number, mapImageId?: string | null, available: boolean, mapImage?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null, checkpoints: Array<{ __typename: 'JrnyCheckpoint', id: string, name: string, order: number, coordinates: { __typename: 'JrnyCoordinates', x: number, y: number } }> } };

export type IGQLCreateCourseMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateCourseInput;
}>;


export type IGQLCreateCourseMutation = { __typename: 'Mutation', jrnyCreateCourse: { __typename: 'JrnyCourse', organizationId: string, id: string, name: string } };

export type IGQLCloneCourseMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCloneCourseInput;
}>;


export type IGQLCloneCourseMutation = { __typename: 'Mutation', jrnyCloneCourse: { __typename: 'JrnyCourse', organizationId: string, id: string, name: string } };

export type IGQLDeleteCourseMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLDeleteCourseMutation = { __typename: 'Mutation', jrnyRemoveCourse: { __typename: 'JrnyCourse', organizationId: string, id: string } };

export type IGQLUpdateCourseMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateCourseInput;
}>;


export type IGQLUpdateCourseMutation = { __typename: 'Mutation', jrnyUpdateCourse: { __typename: 'JrnyCourse', organizationId: string, id: string, name: string, leaderBoardRows: number, available: boolean, mapImageId?: string | null, mapImage?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null, checkpoints: Array<{ __typename: 'JrnyCheckpoint', id: string, name: string, order: number, coordinates: { __typename: 'JrnyCoordinates', x: number, y: number } }> } };


export const CourseDocument = gql`
    query course($id: ID!) {
  jrnyCourse(id: $id) {
    __typename
    organizationId
    id
    name
    leaderBoardRows
    mapImageId
    mapImage {
      ...FileObjectFields
    }
    available
    checkpoints {
      __typename
      id
      name
      order
      coordinates {
        x
        y
      }
    }
  }
}
    ${FileObjectFieldsFragmentDoc}`;

export function useCourseQuery(options: Omit<Urql.UseQueryArgs<IGQLCourseQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLCourseQuery, IGQLCourseQueryVariables>({ query: CourseDocument, ...options });
};
export const CreateCourseDocument = gql`
    mutation createCourse($input: JrnyCreateCourseInput!) {
  jrnyCreateCourse(input: $input) {
    __typename
    organizationId
    id
    name
  }
}
    `;

export function useCreateCourseMutation() {
  return Urql.useMutation<IGQLCreateCourseMutation, IGQLCreateCourseMutationVariables>(CreateCourseDocument);
};
export const CloneCourseDocument = gql`
    mutation cloneCourse($input: JrnyCloneCourseInput!) {
  jrnyCloneCourse(input: $input) {
    __typename
    organizationId
    id
    name
  }
}
    `;

export function useCloneCourseMutation() {
  return Urql.useMutation<IGQLCloneCourseMutation, IGQLCloneCourseMutationVariables>(CloneCourseDocument);
};
export const DeleteCourseDocument = gql`
    mutation deleteCourse($id: ID!) {
  jrnyRemoveCourse(id: $id) {
    __typename
    organizationId
    id
  }
}
    `;

export function useDeleteCourseMutation() {
  return Urql.useMutation<IGQLDeleteCourseMutation, IGQLDeleteCourseMutationVariables>(DeleteCourseDocument);
};
export const UpdateCourseDocument = gql`
    mutation updateCourse($input: JrnyUpdateCourseInput!) {
  jrnyUpdateCourse(input: $input) {
    __typename
    organizationId
    id
    name
    leaderBoardRows
    available
    mapImageId
    mapImage {
      ...FileObjectFields
    }
    checkpoints {
      __typename
      id
      name
      order
      coordinates {
        x
        y
      }
    }
  }
}
    ${FileObjectFieldsFragmentDoc}`;

export function useUpdateCourseMutation() {
  return Urql.useMutation<IGQLUpdateCourseMutation, IGQLUpdateCourseMutationVariables>(UpdateCourseDocument);
};