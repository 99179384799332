import { SvgIcon, SvgIconProps } from "@mui/material";
import { createElement } from "react";

type RenderElement = {
  name: string,
  attributes?: Record<string, any>,
  children?: (RenderElement | string)[],
  key?: number,
  // Added optional 'style' and 'className' for more flexibility
  style?: Record<string, any>,
  className?: string
}

const elementByType: Record<string, React.ElementType> = {
  svg: SvgIcon,
}
export const renderCustomSvgIcon = (element: RenderElement, props?: SvgIconProps): React.ReactElement => {
  const { name: type, attributes, children:jsonChildren } = element;
  // Need to handle type as a string to create component
  const Component = elementByType[type]||type;
  const children = jsonChildren ? jsonChildren.map((child, index) =>
    typeof child === 'string' ? child : renderCustomSvgIcon({ ...child, key: index })
  ) : null;

  return createElement(Component, { ...props, ...attributes }, children);
};
