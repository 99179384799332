import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLUpdateCourseSettingsMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateCourseSettingsInput;
}>;


export type IGQLUpdateCourseSettingsMutation = { __typename: 'Mutation', jrnyUpdateCourseSettings: { __typename: 'JrnyCourseSettings', id: string, theme: string, useCustomGem: boolean, gemSvg: string, gemName: string, gemNamePlural: string, useCustomDiamond: boolean, diamondSvg: string, diamondName: string, diamondNamePlural: string, customThemeSettings: { __typename: 'JrnyCustomThemeColorsV1', primaryColor?: string | null, secondaryColor?: string | null, backgroundGradient1?: string | null, backgroundGradient2?: string | null, lessonGradient1?: string | null, lessonGradient2?: string | null, buttonGradient?: string | null } } };

export type IGQLCourseSettingsQueryVariables = Types.Exact<{
  courseId: Types.Scalars['String']['input'];
}>;


export type IGQLCourseSettingsQuery = { __typename: 'Query', jrnyCourseSettings: { __typename: 'JrnyCourseSettings', id: string, theme: string, useCustomGem: boolean, gemSvg: string, gemName: string, gemNamePlural: string, useCustomDiamond: boolean, diamondSvg: string, diamondName: string, diamondNamePlural: string, customThemeSettings: { __typename: 'JrnyCustomThemeColorsV1', primaryColor?: string | null, secondaryColor?: string | null, backgroundGradient1?: string | null, backgroundGradient2?: string | null, lessonGradient1?: string | null, lessonGradient2?: string | null, buttonGradient?: string | null } } };


export const UpdateCourseSettingsDocument = gql`
    mutation updateCourseSettings($input: JrnyUpdateCourseSettingsInput!) {
  jrnyUpdateCourseSettings(input: $input) {
    id
    theme
    useCustomGem
    gemSvg
    gemName
    gemNamePlural
    useCustomDiamond
    diamondSvg
    diamondName
    diamondNamePlural
    customThemeSettings {
      primaryColor
      secondaryColor
      backgroundGradient1
      backgroundGradient2
      lessonGradient1
      lessonGradient2
      buttonGradient
    }
  }
}
    `;

export function useUpdateCourseSettingsMutation() {
  return Urql.useMutation<IGQLUpdateCourseSettingsMutation, IGQLUpdateCourseSettingsMutationVariables>(UpdateCourseSettingsDocument);
};
export const CourseSettingsDocument = gql`
    query courseSettings($courseId: String!) {
  jrnyCourseSettings(courseId: $courseId) {
    id
    theme
    useCustomGem
    gemSvg
    gemName
    gemNamePlural
    useCustomDiamond
    diamondSvg
    diamondName
    diamondNamePlural
    customThemeSettings {
      primaryColor
      secondaryColor
      backgroundGradient1
      backgroundGradient2
      lessonGradient1
      lessonGradient2
      buttonGradient
    }
  }
}
    `;

export function useCourseSettingsQuery(options: Omit<Urql.UseQueryArgs<IGQLCourseSettingsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLCourseSettingsQuery, IGQLCourseSettingsQueryVariables>({ query: CourseSettingsDocument, ...options });
};