import { Box, useTheme } from "@mui/material";
import BkgImage from "@/assets/journey-bg-bw.jpg";
import { AspectRatio } from "./AspectRatio";

interface AppBackgroundProps {
  gradient1: string;
  gradient2: string;
  backgroundSize?: string;
  isLesson?: boolean;
  sx?: any;
  children?: React.ReactNode;
}

export function AppBackground({
  gradient1,
  gradient2,
  isLesson,
  children,
  sx = {}
}: AppBackgroundProps) {
  const theme = useTheme();
  
  return (
    <AspectRatio ratio="16/9" objectFit="cover">
        <Box
      sx={{
        borderRadius: 1,
        ...theme.journeyBackground?.default,
        backgroundImage: `${gradient1},${gradient2},url(${BkgImage})`,
        backgroundSize: isLesson ? '100% 200%, cover, cover' : 'cover, cover, cover',
        backgroundBlendMode: isLesson ? 'normal, color, normal' : 'color, color, normal',
        ...sx
      }}
    >
      {children}
    </Box>

    </AspectRatio>
  );
} 